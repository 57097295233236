#settings-container form {
  width: 100%;
  min-width: 1050px;
}

.settings-container {
  width: 100%;
  min-width: 1050px;
}

#settings-contact-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#settings-contact-area-2 {
  width: 236px;
  height: 118px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-primary);
  margin-left: 100px;
}

#settings-contact-area-2 img {
  margin: 35px 105px 29px 105px;
  width: 26px;
  height: 24px;
}

#settings-contact-area-2 p {
  margin: 0px 67px 15px 68px;
}

.form-settings-page {
  position: relative;
}

.form-settings-page .form-settings-row {
  max-width: 902px;
}

.form-settings-page .col {
  max-width: 274px;
  margin-right: 40px;
  height: 85px;
}

.form-settings-page .button-area-settings {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.form-settings-page #logoPhoto::-webkit-file-upload-button {
  background-color: transparent;
  border: none;
  display: none;
}

.form-settings-page #logo-container2::-webkit-file-upload-button {
  background-color: transparent;
  border: none;
  display: none;
}

.form-settings-page input[type='file'] {
  position: absolute;
  background: #fff !important;
  padding-left: 60px;
  padding-top: 20px;
}

.button-area-settings Button {
  background-color: #4fa3a1 !important;
  border-radius: 12px;
  width: 136px;
  height: 44px;
  margin-left: 75px;
}

.button-area-settings :first-child {
  margin-top: 34px;
  color: #ffffff;
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-weight: normal;
}

#logoPhoto {
  width: 274px !important;
  height: 143px !important;
  border: none;
  padding-right: 50px;
  padding-top: 100px;
}

#logo-container2 {
  width: 274px !important;
  height: 143px !important;
  border: none;
  padding-right: 50px;
  padding-top: 100px;
}

#organizationPostal,
#phoneNumber,
#companyNumber,
#fax,
#organizationName,
#registrationNumber,
#organizationAddress,
#organizationEmail {
  width: 274px !important;
}

#settings-contact-area-1 h6 {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  width: 408px;
  height: 38px;
}

.contact-title {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  margin-top: 19px;
  font-weight: 600;
}

.contact-email span {
  color: var(--color-secundary);
}

.contact-phone span {
  color: var(--color-secundary);
}

.contact-address span {
  color: var(--color-secundary);
}

.contact-email {
  margin-bottom: 34px;
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  width: 460px;
  text-align: left;
  font-weight: normal;
}

.contact-phone {
  margin-bottom: 34px;
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  width: 460px;
  text-align: left;
  font-weight: normal;
}

.contact-address {
  margin-bottom: 34px;
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  width: 460px;
  text-align: left;
  font-weight: normal;
}

.final-contact {
  max-width: 902px;
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

#business-title {
  color: var(--color-secundary);
  font-family: Lato;
  font-size: 22px;
  line-height: 26px;
  width: 558px;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 6px;
  margin-top: 40px;
  text-transform: uppercase;
}

#business-subtitle {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  margin-left: 10px;
}

.hidden {
  display: none;
}

*:focus {
  outline: 0 !important;
}

.secondary-action a {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  font-weight: 600;
  margin-top: 35px;
  margin-right: 77px;
}

.secondary-action {
  display: flex;
  justify-content: flex-end;
  margin: 0px 40px 40px 40px;
}

#removePhoto {
  cursor: pointer;
}

#uploadPhoto {
  cursor: pointer;
}

#aboutApp h3 {
  font-weight: normal;
}

.tag-icon #removeIcon {
  z-index: 999;
  position: absolute;
  cursor: pointer;
  left: 545px;
  top: 130px;
}

.tag-schema #removeIcon {
  z-index: 999;
  position: absolute;
  cursor: pointer;
  left: 846px;
  top: 130px;
}

.add-signature-btn {
  position: absolute;
  z-index: 99;
  top: 40px;
  left: 220px;
  cursor: pointer;
}

#presetSignature {
  padding-right: 160px;
}

.hidden {
  display: none;
}

.presetSignature {
  background: #f4f4f4
    url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjZweCIgaGVpZ2h0PSIyNnB4IiB2aWV3Qm94PSIwIDAgMjYgMjYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+RDIyRTk5NDktQjRERC00REFBLUI2QjEtNUZCNUJCQzM1RDY5PC90aXRsZT4KICAgIDxnIGlkPSJXZWIiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJTZXR0aW5ncy0tLVdpdGgtYnVzaW5lc3MtbG9nbyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI3OC4wMDAwMDAsIC00ODQuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJjdXN0b21lcl9hZGQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI3OC4wMDAwMDAsIDQ4NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC0zIiBzdHJva2U9IiNGRjAwMDAiIHN0cm9rZS13aWR0aD0iMS4zIj4KICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsLUNvcHkiIGN4PSIxMyIgY3k9IjEzIiByPSIxMi4zNSI+PC9jaXJjbGU+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTMsNiBDMTMuMzMxMzcwOCw2IDEzLjYsNi4yNjg2MjkxNSAxMy42LDYuNiBMMTMuNTk5LDEyLjQgTDE5LjQsMTIuNCBDMTkuNzMxMzcwOCwxMi40IDIwLDEyLjY2ODYyOTIgMjAsMTMgQzIwLDEzLjMzMTM3MDggMTkuNzMxMzcwOCwxMy42IDE5LjQsMTMuNiBMMTMuNTk5LDEzLjYgTDEzLjYsMTkuNCBDMTMuNiwxOS43MzEzNzA4IDEzLjMzMTM3MDgsMjAgMTMsMjAgQzEyLjY2ODYyOTIsMjAgMTIuNCwxOS43MzEzNzA4IDEyLjQsMTkuNCBMMTIuMzk5LDEzLjU5OSBMNi42LDEzLjYgQzYuMjY4NjI5MTUsMTMuNiA2LDEzLjMzMTM3MDggNiwxMyBDNiwxMi42Njg2MjkyIDYuMjY4NjI5MTUsMTIuNCA2LjYsMTIuNCBMMTIuMzk5LDEyLjM5OSBMMTIuNCw2LjYgQzEyLjQsNi4yNjg2MjkxNSAxMi42Njg2MjkyLDYgMTMsNiBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIGZpbGw9IiNGRjAwMDAiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+)
    no-repeat right 15px center;
}

.presetSignature.is-invalid {
  background: #f4f4f4
    url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjZweCIgaGVpZ2h0PSIyNnB4IiB2aWV3Qm94PSIwIDAgMjYgMjYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+RDIyRTk5NDktQjRERC00REFBLUI2QjEtNUZCNUJCQzM1RDY5PC90aXRsZT4KICAgIDxnIGlkPSJXZWIiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJTZXR0aW5ncy0tLVdpdGgtYnVzaW5lc3MtbG9nbyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI3OC4wMDAwMDAsIC00ODQuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJjdXN0b21lcl9hZGQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI3OC4wMDAwMDAsIDQ4NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC0zIiBzdHJva2U9IiNGRjAwMDAiIHN0cm9rZS13aWR0aD0iMS4zIj4KICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsLUNvcHkiIGN4PSIxMyIgY3k9IjEzIiByPSIxMi4zNSI+PC9jaXJjbGU+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTMsNiBDMTMuMzMxMzcwOCw2IDEzLjYsNi4yNjg2MjkxNSAxMy42LDYuNiBMMTMuNTk5LDEyLjQgTDE5LjQsMTIuNCBDMTkuNzMxMzcwOCwxMi40IDIwLDEyLjY2ODYyOTIgMjAsMTMgQzIwLDEzLjMzMTM3MDggMTkuNzMxMzcwOCwxMy42IDE5LjQsMTMuNiBMMTMuNTk5LDEzLjYgTDEzLjYsMTkuNCBDMTMuNiwxOS43MzEzNzA4IDEzLjMzMTM3MDgsMjAgMTMsMjAgQzEyLjY2ODYyOTIsMjAgMTIuNCwxOS43MzEzNzA4IDEyLjQsMTkuNCBMMTIuMzk5LDEzLjU5OSBMNi42LDEzLjYgQzYuMjY4NjI5MTUsMTMuNiA2LDEzLjMzMTM3MDggNiwxMyBDNiwxMi42Njg2MjkyIDYuMjY4NjI5MTUsMTIuNCA2LjYsMTIuNCBMMTIuMzk5LDEyLjM5OSBMMTIuNCw2LjYgQzEyLjQsNi4yNjg2MjkxNSAxMi42Njg2MjkyLDYgMTMsNiBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIGZpbGw9IiNGRjAwMDAiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+)
    no-repeat right 15px center;
}

#logoPhoto {
  color: transparent;
}
#logo-container2 {
  color: transparent;
}

.main-titles {
  color: var(--color-secundary);
  font-family: Lato;
  font-size: 22px;
  line-height: 26px;
  text-align: left;
  font-weight: normal;
  text-transform: uppercase;
}

.required-field {
  color: #4fa3a1;
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  font-weight: normal;
}

.label-container-required {
  width: 265px;
  display: flex;
  justify-content: space-between;
}

.label-container-user-required {
  width: 235px;
  display: flex;
  justify-content: space-between;
}

.label-container-user-required-big {
  width: 550px;
  display: flex;
  justify-content: space-between;
}

.settings-about-policy-button {
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
