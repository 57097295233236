.set-password-explanation {
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  color: #666666;
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  width: 558px;
  text-align: left;
  font-weight: normal;
  margin-bottom: 40px;
}
.set-password-success-image {
  padding: 40px;
}
.input-password {
  margin-top: 5px;
}

.submit-container-button {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  width: 660px;
}

.submit-container-button button {
  min-width: 0;
  cursor: pointer;
  color: #ffffff;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
