.table-certificate-table-header {
  background-color: #ff0000;
  border-radius: 12px 12px 0 0;
  padding: 4px 0;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.table-header-text {
  width: 100%;
  color: white;
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
