.container-customer .modal-content {
  border: none;
  min-width: 568px;
  min-height: 568px;
}

.container-customer .modal-body {
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 568px;
  max-height: 528px;
}

.customer-head-title {
  color: #4fa3a1;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.customer-header {
  border-color: red;
}

.add-customer-footer {
  cursor: pointer;
}

.add-customer-footer img {
  width: 27px;
  height: 27px;
  margin-top: 5px;
}

.add-customer-footer p {
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  color: var(--color-primary);
  margin-top: 8px;
  margin-left: 21px;
}

.modal-customer {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  padding: 15px 20px;
  flex-direction: row;
  justify-content: space-between;
}
.modal-customer .square-card {
  margin: 0;
}

.input-customer {
  background-color: #f9f9f9;
  border-radius: 12px;
  width: 528px;
  height: 36px !important;
  margin: 20px;
  padding-left: 20px;
  font-weight: normal;
}

.customer-modal-box {
  width: 236px;
  height: 182px;
  background-color: #f2f2f2;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  margin-right: 28px;
  margin-bottom: 40px;
  cursor: pointer;
}
.customer-modal-box img {
  width: 236px;
  height: 182px;
  background-size: cover;
}

.customer-modal-box p {
  border-radius: 0 0 12px 12px;
  width: 236px;
  height: 34px;
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  padding: 7px 20px 8px 20px;
}

.search-icon {
  position: absolute;
  top: 26px;
  left: 515px;
  cursor: pointer;
}

.selected {
  border: 2px solid #4fa3a1;
  border-radius: 14px;
  width: 240px;
  height: 180px;
}
