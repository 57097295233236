.modal-title {
  color: #4fa3a1;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  text-transform: uppercase;
}
.modal-red-header {
  border-color: red;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-content: center;
}

.modal-content {
  border: none;
  border-radius: 12px;
}
.modal-header {
  padding: 15px 35px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.modal-header-left-action {
  cursor: pointer;
  color: var(--color-primary);
  text-transform: initial;
  text-align: left;
  padding: 10px;
  margin: -10px;
}
.modal-header-right-action {
  cursor: pointer;
  color: var(--color-primary);
  text-transform: initial;
  text-align: left;
  padding: 10px;
  margin: -10px;
}

.confirmation-modal-backdrop {
  z-index: 1059;
}
.confirmation-modal {
  z-index: 1060;
}
.confirmation-modal .modal-content {
  z-index: 1160;
  background-color: #ffffff;
  border-radius: 12px;
  width: 638px;
  height: 349px;
  padding: 40px;
  position: fixed;
}

.confirmation-modal .modal-title {
  color: var(--color-secundary);
  font-family: Lato;
  font-size: 22px;
  line-height: 26px;
  width: 558px;
  text-align: left;
}

.confirmation-modal .modal-body {
  padding: 5px 0;
}
.confirmation-modal .modal-footer {
  padding: 0;
  border: none;
}
.confirmation-modal .modal-footer button {
  min-width: 150px;
}

.modal-v1 .modal-content {
  z-index: 1160;
  background-color: #ffffff;
  border-radius: 12px;
  width: 638px;
  height: 394px;
  position: fixed;
}
/**/

.modal-v1 .modal-header {
  border-bottom: 1px solid var(--color-secundary);
  justify-content: center;
}
.modal-v1 .modal-title {
  text-align: center;
}

.modal-v1 .modal-body {
  padding: 15px 40px 0 40px;
  border: none;
}
.modal-v1 .modal-footer {
  padding: 0 40px 40px 40px;
  border: none;
}
.modal-v1 .modal-footer button {
  min-width: 150px;
}
.modal-md .modal-dialog {
  max-width: 638px;
}

/**/
.image-modal-backdrop {
  z-index: 1059;
}
.image-modal {
  z-index: 1060;
}
.image-modal .modal-content {
  z-index: 1160;
  background-color: #ffffff;
  border-radius: 12px;
  width: 638px;
  height: 465px;
  padding-right: 20px;
  padding-left: 40px;
  position: fixed;
}

.image-modal .modal-title {
  color: var(--color-secundary);
  font-family: Lato;
  font-size: 22px;
  line-height: 26px;
  width: 558px;
  text-align: left;
}

.image-modal .modal-body {
  padding: 5px 0;
}
.image-modal .modal-footer {
  padding: 0;
  border: none;
}
.image-modal .modal-footer button {
  min-width: 150px;
}
