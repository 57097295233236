main {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.site-header {
  background: var(--color-background-secundary);
  width: 100vw;
  min-height: 100px;
  height: 100px;
  display: flex;
  justify-content: space-between;
}

.site-header .title {
  font-size: 36px;
  font-weight: 600;
  color: #370f08;
  padding-top: 20px;
  padding-left: 40px;
  line-height: 43px;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-search img {
  position: absolute;
}

.header-search {
  margin-right: 40px;
  width: 236px;
  height: 36px !important;
}

.header-search-input {
  width: 100%;
  height: 100% !important;
  border-radius: 12px !important;
  padding-top: 0px !important;
  padding-left: 20px !important;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-primary);
}

.header-search-image {
  margin-top: 7px;
  margin-right: 10px;
  margin-left: 200px;
}

.site-header-user-menu-icon {
  margin-right: 40px;
  cursor: pointer;
}

.site-header-user-menu-content {
  position: absolute;
  background: #ffffff;
  width: 343px;
  height: 200px;
  top: 80px;
  right: 40px;
  border-radius: 12px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  z-index: 999;
}

.user-menu-data {
  width: 100%;
  padding-bottom: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #f2f2f2;
}

.user-menu-data span {
  margin-top: 16px;
  color: var(--color-primary);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.user-menu-data svg {
  width: 50px;
  height: 50px;
  border: 1px solid black;
  margin-right: 20px;
}

.logout-button {
  margin: -20px 0px;
  padding: 20px 20px 20px 0;
  cursor: pointer;
  width: 100%;
  color: #666666;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.user-menu-upgrade-options {
  min-height: 59px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #f2f2f2;
}

.user-menu-upgrade-options a {
  color: #4fa3a1;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  text-align: left;
}

.site-menu {
  height: 50px !important;
  display: flex;
  align-items: center;
  background-color: var(--color-secundary);
  width: 100vw;
}

.site-menu ul {
  margin-left: 40px !important;
  padding: 12px 0 12px 0 !important;
}

.site-menu ul li a {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;

  display: inline-block;

  /* visual do link */
  text-decoration: none;
}

.site-menu ul li a img {
  margin-right: 12px;
  margin-top: 0px;
  width: 26px;
  height: 26px;
}

.site-menu ul li a .menu-icon-active {
  display: none;
}

.site-menu ul li .menu-active .menu-icon-active {
  display: inline;
}

.site-menu ul li .menu-active img {
  display: none;
}

.site-menu ul li .menu-active span {
  border-bottom: solid #ffffff 1px;
}

.site-menu ul li a span:hover {
  border-bottom: solid #ffffff 1px;
}

.site-menu ul {
  margin: 0px;
  list-style: none;
}

.site-menu ul li {
  display: inline;
  margin-right: 40px;
}

.site-menu-title {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #4fa3a1;
  width: 100%;
}

.site-content {
  height: 100vh;
  max-height: 100vh;
  background: var(--color-background-secundary);
  padding: 40px 0px 0px 0px;
  overflow: auto;
  width: 100vw;
  overflow-x: hidden;
}

.site-footer {
  height: 22px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-primary);
  padding-top: 2px;
  background-color: #ffffff;
}

.footer {
  padding-left: 0px !important;
  position: sticky;
  top: 100vh;
}

#publicMainContainer {
  height: 100vh;
}

.rectangle-copy-2 {
  background-color: #4fa3a1;
  width: 100vw;
  height: 1px;
}

.site-content h2 {
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
}

.back-certificate-menu {
  background-color: white;
  display: flex;
  width: 100vw;
  height: 35px;
}

.back-certificate-menu img {
  margin-left: 40px !important;
}

.backmenu-bar {
  background-color: var(--color-secundary);
  width: 1440px;
  height: 1px;
}
