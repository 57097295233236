.invite-expired-explanation {
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  color: #666666;
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  width: 558px;
  text-align: left;
  font-weight: normal;
  margin-bottom: 40px;
}
.invite-expired-submit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.invite-expired-submit-container button {
  min-width: 0;
  cursor: pointer;
  color: #ffffff;
}
