.board-grid-card {
  width: 236px;
  min-width: 236px;
  height: 266px;
  margin-right: 40px;
  margin-bottom: 40px;
  border-radius: 12px;
  background-color: #f2f2f2;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}

.board-grid-card p {
  margin: 0;
}

.board-grid-card-button {
  background-color: unset;
  height: 100%;
  width: 100%;
  border: 0;
}

.board-grid-card a {
  height: 100%;
}

.board-grid-card .title-top {
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.board-grid-card .title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px 12px 0 0;
  padding: 0 10px;
  height: 25px;
}

.board-grid-card .board-grid-card-body {
  border-radius: 0 0 12px 12px;
  border-top: 2px solid #ff0000;
  display: flex;
  padding: 10px;
  gap: 8px;
  /* height: calc(100% - 27px); */
  height: inherit;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: #ffffff;
}

.board-grid-card-body.completed {
  border-top: 2px solid #4fa3a1;
}

.board-grid-card .text {
  background-color: #ffffff;
  height: 22px;
  font-weight: 400;
  font-family: Lato;
  color: #666666;
  font-size: 16px;
  text-align: left;
}

.board-grid-card .text.green {
  color: #4fa3a1;
  font-weight: 600;
  font-family: Lato;
}

.add-board {
  width: 100%;
  height: 100%;
  margin-right: 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-board a {
  width: 100%;
  padding: 40px 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-board p {
  color: var(--color-primary);
  margin: 20px 0 0 0;
  font-size: 16px;
}

.boardgrid-buttons-wrapper {
  display: flex;
  width: 25%;
  justify-content: flex-end;
  align-items: center;
}

.delete-icon-btn {
  height: 16px;
  width: 16px;
  margin-left: 20px;
  cursor: pointer;
}
