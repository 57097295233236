#verify-container-modal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(51, 51, 51, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.verify-container {
  position: relative;
  padding: 40px;
  margin-top: 163px;
  margin-bottom: 164px;
  min-width: 638px;
  max-width: 638px;
  min-height: 698px;
  max-height: 698px;
  background: var(--color-background-secundary);
  border-radius: 12px;
}
.verify-container .subtitle {
  margin-bottom: 20px;
}

.verify-container img {
  width: 598px;
  height: 386px;
  background: #f9f9f9;
  margin-left: -20px;
}

.verify-container .explanation {
  color: #666666;
  font-size: 16px;
}

.verify-btn-area {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  width: calc(100% - 80px);
  align-items: flex-end;
}

.verify-btn-area a {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 600;
}
