.tag-file {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  text-align: left;
  position: absolute;
  padding: 28px 30px 5px 30px;
  top: 28px;
  left: 50px;
  cursor: pointer;
}

.size-2-upload-label {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 17px;
  line-height: 17px;
  font-weight: 400;
  text-align: left;
  position: absolute;
  padding: 0.375rem 0.75rem;
  top: 37px;
  cursor: pointer;
}

.tag-file p {
  margin: 0;
}

.big-input-upload-add-label {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 114px;
  width: calc(100% - 15px);
}

.big-input-upload-remove-label {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 27px;
  padding: 25px;
  background-repeat: no-repeat;
  background-position: center;
  right: 0;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.big-input-upload-remove-label {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 27px;
  padding: 25px;
  background-repeat: no-repeat;
  background-position: center;
  right: 0;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.photo-upload-1 {
  cursor: pointer;
  width: 50px !important;
  height: 50px !important;
  color: transparent !important;
  border-radius: 12px !important;
}
.photo-upload-1 input[type='file'] {
  position: absolute !important;
  background: #fff !important;
  padding-left: 60px !important;
  padding-top: 20px !important;
}
.photo-upload-1::-webkit-file-upload-button {
  width: 50px !important;
  height: 50px !important;
  color: transparent !important;
  border-radius: 12px !important;
  display: none !important;
}

.photo-upload-2 {
  cursor: pointer;
  height: 50px !important;
  color: transparent !important;
  border-radius: 12px !important;
}
.photo-upload-2 input[type='file'] {
  display: none !important;
}
.photo-upload-2::-webkit-file-upload-button {
  display: none !important;
}

.photo-upload-3 {
  height: 157px !important;
  color: transparent !important;
}
.photo-upload-3 input[type='file'] {
  position: absolute !important;
  background: #fff !important;
  padding-left: 60px !important;
  padding-top: 20px !important;
}

.photo-upload-3::-webkit-file-upload-button {
  height: 157px !important;
  border: none !important;
  color: transparent !important;
  display: none !important;
}
