.collapse-customers-locations .title {
  height: 50px;
  background: var(--color-secundary);
  color: #ffffff;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 20px;
}

.collapse-customers-locations .card {
  border: none;
}

.collapse-customers-locations .card-header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 0;
  margin-top: 20px;
  background-color: #f4f4f4;
  border-radius: 12px;
  border: none;
}

.collapse-customers-locations .card-header button {
  background-color: #f4f4f4;
  border-radius: 12px !important;
  padding-left: 0;
  padding-right: 0;
}

.collapse-customers-locations .card-header button:active {
  background-color: #f4f4f4 !important;
  border: none;
  border-radius: 0;
  border-right: solid 1px rgba(102, 102, 102, 0.2);
}

.collapse-customers-locations .card-header button:focus {
  box-shadow: none !important;
}

.collapse-customers-locations .card-header button:last-child {
  border: none;
}

.collapse-customers-locations .card-body label.value-title {
  color: #4fa3a1;
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  background: none;
}

.collapse-customers-locations .card-body label.value {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.collapse-customers-locations .card-body .col {
  margin-top: 20px;
  margin-bottom: 20px;
}

.collapse-customers-locations .card-body .section-title {
  color: #370f08;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.collapse-customers-locations .divider-item {
  background-color: rgba(102, 102, 102, 0.2);
  width: 1px;
  height: 30px;
}

.collapse-customers-locations .buttons-item-table {
  display: flex;
  justify-content: flex-end;
}

.collapse-customers-locations .buttons-item-table img {
  padding: 3px 20px;
  box-shadow: none !important;
}

.collapse-customers-locations .hide-btn {
  display: none;
}

.collapse-customers-locations .item-table-title {
  color: #4fa3a1;
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  width: 230px;
  text-align: left;
}

.collapse-customers-locations .item-table-result {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 17px;
  font-weight: normal;
  line-height: 20px;
  width: 177px;
  text-align: left;
}

.collapse-customers-locations .item-details {
  margin: 20px;
}
