.square-card {
  width: 236px;
  min-width: 236px;
  height: 182px;
  margin-right: 40px;
  border-radius: 12px;
  background-color: #f2f2f2;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}

.square-card img {
  width: 236px;
  height: 148px;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
}

.square-card p {
  background-color: #fff;
  height: 34px;
  color: var(--color-primary);
  font-size: 16px;
  padding: 6px 20px;
  border-radius: 0 0 12px 12px;
  width: 100%;
}

.add-card {
  width: 236px;
  min-width: 236px;
  height: 118px;
  margin-right: 40px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-card a {
  width: 100%;
  padding: 40px 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-card p {
  color: var(--color-primary);
  margin: 20px 0 0 0;
  text-transform: uppercase;
}

.rectangle-card {
  width: 182px;
  min-width: 182px;
  height: 236px;
  margin-right: 40px;
  border-radius: 12px;
  background-color: #f2f2f2;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}

.rectangle-card p {
  margin: 0;
}

.rectangle-card .title-top {
  border-radius: 12px 12px 0 0;
  padding: 4px 10px;
  height: 25px;
  color: #666666;
  font-weight: 600;
}
.rectangle-card .rectangle-card-image {
  display: flex;
  height: 157px;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.rectangle-card .rectangle-card-image img {
  width: 60px;
  height: 66px;
  padding: 20px;
  object-fit: cover;
}

.rectangle-card .title-client {
  background-color: #ffffff;
  height: 22px;
  padding: 2px 10px 0 10px;
  font-weight: 600;
}
.rectangle-card .title {
  background-color: #ffffff;
  border-radius: 0 0 12px 12px;
  height: 30px;
  color: #666666;
  font-weight: 400;
  font-size: 17px;
  padding: 0 10px 8px 10px;
}

.highlight-card {
  border: 1px solid #4fa3a1;

  border-radius: 12px;
  box-shadow: 0 0 10px 0 #4fa3a1;
}
