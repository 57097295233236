.btn {
  height: 50px;
  padding: 9px 20px 10px 20px;
}

.button-primary.focus,
.button-primary:focus {
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgb(63 131 130 / 50%);
}

.button-primary {
  height: 44px;
  min-width: 144px;
  background: #4fa3a1;
  border: 1px solid #4fa3a1;
  border-radius: 12px;
}

/* color when button is active */
.button-primary:not(:disabled):not(.disabled):active {
  background-color: #3f8382;
  border-color: #3f8382;
}
.button-primary.disabled,
.button-primary:disabled {
  background-color: #4fa3a1;
  border-color: #4fa3a1;
}

/* color when button is hover, focus */
.button-primary:hover:not(.disabled),
.button-primary:focus:not(.disabled),
.button-primary:active:not(.disabled),
.button-primary:visited:not(.disabled),
.open > .dropdown-toggle.button-primary {
  background-color: #3f8382;
  border-color: #3f8382;
  color: #f4f4f4;
  border-radius: 12px;
}

.button-red {
  background-color: #ffffff;
  border: 1px solid var(--color-secundary) !important;
  border-radius: 12px;
  color: var(--color-primary);
  /* box-shadow-color: var(--color-secundary); */
}

.button-red.filled {
  color: #fff;
  background-color: var(--color-secundary) !important;
  border-color: var(--color-secundary) !important;
}
.button-red.filled.disabled,
.button-red.filled:disabled {
  color: #fff;
}

.button-red.disabled,
.button-red:disabled {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: var(--color-primary);
}

.button-red.focus,
.button-red:focus {
  color: var(--color-primary);
  box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 50%);
}

.button-red:not(:disabled):not(.disabled):active:focus,
.show > .button-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--color-secundary);
}
.button-red:hover:not(.disabled),
.button-red:focus:not(.disabled),
.button-red:active:not(.disabled),
.button-red:visited:not(.disabled),
.open > .dropdown-toggle.button-red {
  color: #fff;
  background-color: var(--color-secundary) !important;
  border-color: var(--color-secundary) !important;
}

.button-secondary {
  height: 44px;
  min-width: 144px;
  background: white;
  color: var(--color-primary);
  border: none !important;
  border-radius: 12px;
}

/* color when button is active */
.button-secondary:not(:disabled):not(.disabled):active {
  background-color: #d3d4d5;
}

/* color when button is hover, focus */
.button-secondary:hover:not(.disabled),
.button-secondary:focus:not(.disabled),
.button-secondary:active:not(.disabled),
.button-secondary:visited:not(.disabled),
.open > .dropdown-toggle.button-secondary {
  color: var(--color-primary);
  background-color: #d3d4d5;
}
