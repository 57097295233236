.input-signature {
  background-color: transparent;
  border-color: var(--color-primary);
  font-family: Lato;
  font-size: 17px;
  text-align: left;
  font-weight: normal;
  padding: 0;
}
.input-signatureName {
  background-color: transparent;
  border-color: var(--color-primary);
  font-family: Lato;
  font-size: 17px;
  text-align: left;
  font-weight: normal;
  padding-left: 15px;
}

#signature::-webkit-file-upload-button {
  background-color: transparent;
  width: 0;
  color: transparent;
  border: none;
  margin-top: 12px;
}

.uploading-btn {
  min-width: 18px;
  min-height: 18px;
  margin-left: 560px;
  margin-top: 12px;
  margin-bottom: 16px;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  top: 30px;
  right: 30px;
}

.label-signature {
  font-family: Lato;
  font-size: 16px;
  color: var(--color-primary);
  margin-top: 21px;
}

.cancel-btn {
  cursor: pointer;
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

.export-btn {
  background-color: #4fa3a1;
  border-radius: 12px;
  width: 154px;
  height: 44px;
  border: none;
}

.export-btn:hover {
  color: #fff;
  background-color: #3f8382;
  border: none;
}
