#user-onboarding {
  position: relative;
}

#user-onboarding div.image {
  width: 100%;
  margin-left: -20px;
  /* background: #f9f9f9; */
}

#user-onboarding div.image img {
  width: 598px;
  height: 387px;
  margin-bottom: 10px;
}

#user-onboarding div.description {
  color: var(--color-primary);
  font-family: Lato;
  height: 58px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-bottom: 39px;
}

#user-onboarding div.button-area {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  vertical-align: bottom;
  position: absolute;
  bottom: 40px;
  right: 40px;
  gap: 20px;
}
#user-onboarding div.button-area .button-primary {
  margin: 0;
  font-weight: 600;
}
#user-onboarding div.button-area .button-secondary {
  margin: 0;
  font-weight: 600;
}

.pagination {
  position: absolute;
  bottom: 40px;
}

.information-step {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  width: 558px;
  text-align: left;
  font-weight: normal;
  margin-left: 40px;
  margin-bottom: 40px;
}

.subtitle-step2 {
  color: #370f08;
  font-family: Lato;
  font-size: 36px;
  line-height: 43px;
  width: 558px;
  text-align: left;
  font-weight: 600;
  margin-left: 40px;
  margin-bottom: 20px;
}

.skip-link {
  color: var(--color-primary);
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  font-weight: 600;
  margin-top: 26px;
  padding-right: 40px;
}

.btn-div {
  display: flex;
}

.subtitle-step4 {
  color: #370f08;
  font-family: Lato;
  font-size: 36px;
  line-height: 43px;
  width: 558px;
  text-align: left;
  font-weight: 600;
  margin-left: 40px;
  margin-bottom: 20px;
}

.form-step4 {
  margin-bottom: 77px;
}

.form-step3 {
  margin-bottom: 21px;
}

.form-step2 {
  margin-bottom: 63px;
}

#filetype-onboard {
  position: absolute;
  width: 50px;
  height: 50px;
  border: none;
  background: #f4f4f4 url('../../../assets/images/iconset/no_photo.svg') center
    center/20px 20px no-repeat !important;
  color: transparent;
}

#upload-label-onboard {
  margin-left: 90px;
  margin-top: 35px;
}

#filetype-onboard-logo {
  background-color: #f4f4f4 !important;
  border-radius: 12px;
  width: 264px;
  height: 50px;
  color: transparent;
  padding: 0px;
}
#filetype-onboard-logo::-webkit-file-upload-button {
  background-color: transparent;
  background-image: url('../../../assets/images/iconset/customer_add_red.svg');
  color: transparent;
  border: none;
  width: 26px;
  height: 26px;
  margin-left: 228px;
  margin-right: 10px;
  margin-top: 12px !important;
}
