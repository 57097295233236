#forgot-login {
  margin-top: 105px;
  margin-bottom: 85px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: Lato;
  color: var(--color-primary);
}

#create-account {
  font-size: 16px;
  font-weight: 400;
  font-family: Lato;
  text-align: center;
  color: var(--color-primary);
}
