.input_placeholder {
  font-weight: normal;
  font-size: 17px;
  position: absolute;
  top: 40px;
  left: 30px;
  cursor: pointer;
}

.input_placeholder.disabled,
.input_icon_placeholder.disabled {
  cursor: auto;
  color: var(--color-primary);
}

.input_icon_placeholder {
  position: absolute;
  top: 40px;
  right: 15px;
  cursor: pointer;
}
