.container-signature-selector .modal-content {
  border: none;
  min-width: 568px;
  min-height: 410px;
}

.container-signature-selector .modal-body {
  padding: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 568px;
  max-height: 410px;
}

.table-delete-btn {
  min-width: 24px;
  height: 24px;
  margin-top: 14px;
  margin-left: 50px;
  padding-right: 20px;
  cursor: pointer;
  border-right: 1px solid rgba(102, 102, 102, 0.2);
}

.table-select-signature {
  min-width: 24px;
  height: 24px;
  margin-top: 14px;
  cursor: pointer;
  padding-left: 20px;
}

.signature-export {
  width: 26px;
  height: 26px;
}

.export-signature-text {
  font-family: Lato;
  font-weight: normal;
  font-size: 16px;
  margin-left: 22px;
  margin-top: 3px;
}

.new-signature {
  cursor: pointer;
}

.signature-footer {
  margin-bottom: 25px;
}

.signature-row {
  display: flex;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid rgba(102, 102, 102, 0.2);
}

.description-signature {
  color: var(--color-primary);
  margin-top: 17px;
  margin-left: 0;
  width: 322px;
}

.signature-img-container {
  width: 87px;
  height: 44px;
}
.signature-img {
  width: 100%;
  height: 100%;
  background-size: cover;
}
