.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: var(--color-primary);
  background: var(--color-background);
  overflow-x: hidden;
}
.white-card {
  position: relative;
  width: 638px;
  height: 698px;
  background: var(--color-background-secundary);
  border-radius: 12px;
  padding: 40px;
  padding-bottom: 10px;
  margin: 60px auto 20px auto;
}
.white-card-body {
  height: 396px;
  margin-bottom: 30px;
}

.white-card-long-body {
  height: 515px;
}

.white-card-title {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: Lato;
  color: var(--color-secundary);
}

.white-card-subtitle {
  font-size: 34px;
  font-weight: 600;
  font-family: Lato;
  color: var(--color-primary-dark);
  margin-left: 0px;
  margin-bottom: 20px;
}

.white-card-text-body {
  padding: 20px 0 30px 0;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  font-family: Lato;
  line-height: 19px;
}
.white-card-text.margin-top {
  padding-top: 50px;
}
.white-card-text-body.no-padding-top {
  padding-top: 0;
}
.white-card-text.margin-bottom {
  padding-bottom: 50px;
}
