.container-preset .modal-content {
  border: none;
  background-color: #ffffff;
  border-radius: 12px;
  width: 568px;
  height: 274px;
}

.container-preset .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
  width: 568px;
  height: 274px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.preset-head-title {
  color: #4fa3a1;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.preset-header {
  border-color: red;
  padding: 15px 20px !important;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
}
