.add-container-equipment .modal-content {
  border: none;
  min-width: 638px;
  min-height: 534px;
}

.equipment-title {
  color: #4fa3a1;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.equipment-head-title {
  color: #4fa3a1;
  text-align: center;
}

.add-equipment-header {
  border-color: red;
}

.cancel-btn {
  cursor: pointer;
  color: var(--color-primary);
  font-family: Lato;
  font-size: 17px;
  line-height: 20px;
  text-align: left;
  text-transform: capitalize;
  padding-left: 35px;
  padding-top: 5px;
}

.save-btn {
  cursor: pointer;
  color: var(--color-primary);
  font-family: Lato;
  font-size: 17px;
  line-height: 20px;
  text-align: right;
  text-transform: capitalize;
  padding-right: 35px;
  padding-top: 5px;
}

.form-equipment {
  margin-left: 35px;
  margin-top: 21px;
}

.date-input {
  padding-left: 15px !important;
  padding-bottom: 10px !important;
}
