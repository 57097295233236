@font-face {
  font-family: Lato;
  src: local('Lato'), url(./Lato-Regular.ttf) format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato'), url(./Lato-Bold.ttf) format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato'), url(./Lato-Italic.ttf) format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

:root {
  --color-background: #ff0000;
  --color-background-secundary: #ffffff;
  --color-primary: #666666;
  --color-primary-dark: #370f08;
  --color-secundary: #ff0000;
  --color-text-base: #666666;
}

* {
  font-family: 'Lato';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow: hidden;
}

html,
body,
#root {
  height: 100vh;
  width: 100vw;
}

body {
  background: var(--color-background-secundary);
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.bold {
  font-weight: 600;
}
.bold:hover {
  font-weight: 600;
}

a.red {
  color: var(--color-secundary);
}

a.gray {
  color: var(--color-primary);
}

a {
  color: #4fa3a1;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

a:hover {
  color: #518281;
  text-decoration: none;
}
a.red:hover {
  color: #990000;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-primary);
}

.invalid-feedback {
  display: flex;
  margin-top: 0;
  padding-inline: 10px;
}

input {
  height: 50px !important;
  background-color: #f4f4f4 !important;
  border-radius: 12px !important;
  border: none;
}
.form-control {
  border: 1px transparent solid;
}

textarea {
  resize: none;
  padding: 0.75rem;
  font-weight: 400 !important;
  background-color: #f4f4f4;
  border-radius: 12px;
  border: 1px transparent solid;
}

.was-validated input:invalid,
textarea:invalid {
  border: 1px #ff0000 solid;
}

.form-control.is-invalid {
  border: 1px #ff0000 solid !important;
  background-image: none !important;
}

.title {
  font-size: 36px;
  font-weight: 600;
  color: var(--color-secundary);
}

.subtitle {
  font-size: 36px;
  font-weight: 600;
  color: var(--color-primary-dark);
  margin-left: 0px;
  margin-bottom: 33px;
}

.divider {
  width: calc(100% - 80px);
  border: 1px solid #f2f2f2;
  margin: 0 40px 40px 40px;
}

h2 {
  font-size: 22px;
  font-weight: 600;
  color: var(--color-secundary);
}

.section-title {
  font-weight: 400;
  text-transform: uppercase;
}

.section-subtitles {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 400;
  text-transform: initial;
}

h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: 40px;
}

h6 {
  margin-bottom: 40px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 15px;
  padding-left: 15px;
}

.range-component-separator {
  margin: 15px;
}

div.float-right {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.table {
  margin-top: 15px;
  margin-right: 20px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  -webkit-border-radius: 12px 12px 0 0;
  -moz-border-radius: 12px 12px 0 0;
}

.table thead {
  background-color: var(--color-secundary);
  height: 50px;
  width: 810px;
}

.table thead tr th {
  border: none;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.table th {
  padding: 0.45rem 0 0.45rem 0;
}

.table th div {
  padding: 0.3rem 0 0.3rem 0;
  border-right: 2px solid #fff;
}

.table th:last-child div {
  border-right: none;
}

.table .invisible {
  border-right: none;
}

.table tr td {
  padding: 3px;
  border-top: none !important;
}

.table tr td input,
.table tr td select,
.table tr td textarea {
  width: 100% !important;
  background-color: #f4f4f4 !important;
  border-radius: 12px !important;
  height: 50px !important;
  padding: 10px;
}

.table tr td textarea {
  min-height: 50px !important;
  max-height: fit-content !important;
  padding: 5px !important;
}

.table tr td .btn-remove-row {
  background: none;
  border: none;
  margin-top: 5px;
}

.form-label {
  font-size: 16px;
  min-height: 19px;
  font-weight: 600;
  color: var(--color-primary);
  line-height: 19px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.textAreaChars {
  font-weight: 400;
  color: var(--color-primary);
  text-align: end;
  float: right;
}

#table-modal-checkbox-auxLabel {
  width: 100%;
  margin-left: 20px;
  padding: 7px 10px;
  border-radius: 12px;

  background: rgba(0, 0, 0, 0.03);
  font-weight: normal;
}

.table-modal-checkbox label {
  display: flex;
  flex-direction: row-reverse;
}

.table-modal-checkbox input.form-check-input + label:before {
  width: 0;
  height: 0;
  padding: 10px;
  margin-top: 1%;
  margin-left: 10px;

  border-radius: 20px;
}

.table-modal-checkbox input.form-check-input:checked + label:before {
  border: 2px solid red;
}

.table-modal-checkbox input.form-check-input:checked + label:after {
  content: '';
  position: absolute;
  left: 32.5%;
  top: 0.75rem;
  background: var(--color-secundary);
  width: 3px;
  height: 3px;
  box-shadow: 0px 0 0 #ff0000, 3px 0 0 #ff0000, 6px 0 0 #ff0000,
    6px 0px 0 #ff0000, 6px -3px 0 #ff0000, 6px -6px 0 #ff0000,
    6px -9px 0 #ff0000, 6px -12px 0 var(--color-secundary);
  transform: rotate(45deg);
}

.combo-wrapper {
  position: relative;
}

.combo-arrow {
  position: absolute;
  top: 25px;
  right: 18px;
}

.combo-arrow-small {
  position: absolute;
  top: 45%;
  right: 2%;
}

.form-group-wrapper {
  display: flex;
  width: 100%;
}

#form-cert h3 {
  font-weight: normal;
}

#form-cert div.form-row > .form-label {
  margin-left: 15px !important;
}

.hide {
  display: none;
}

.react-datepicker__input-container input {
  padding-left: 10px;
}

input[type='date']::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

.certificate-icons {
  margin-right: 40px;
}

.certificate-icons img {
  width: 26px;
  height: 26px;
}

.context-icon-back {
  cursor: pointer;
}

.table-textbox {
  margin-top: 20px;
}

#Industrial,
#Domestic,
#Commercial,
#Other {
  width: 0;
  height: 0;
}

.subHeader-column {
  display: none;
}

/************ REVIEWED ************/

.Toastify__toast-theme--light {
  width: 505px;
  height: 91px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 7px 16px 0 rgba(87, 87, 87, 0.2);
  color: #370f08;
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}
.Toastify__toast-container {
  z-index: 99000;
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 13em;
}

.row + .row {
  margin-top: 1rem;
}

.row {
  margin-right: 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 0;
}

hr {
  border-top: 1px solid rgba(102, 102, 102, 0.2);
  width: 100%;
}

.truncate {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.page-container {
  max-width: none;
  padding-bottom: 100px;
}

.form-title {
  font-size: 22px;
  line-height: 26px;
  font-weight: 400;
  text-transform: uppercase;
}
.form-subtitle {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  white-space: pre-line;
}

.buttons-right-area {
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
}
