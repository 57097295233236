.circuits-page-form {
  max-width: none !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}
#manage-certificate {
  height: 100%;
}
#manage-certificate .row {
  height: 100%;
  padding-bottom: 0 !important;
}
