.certificate-table-header {
  background-color: #ff0000;
  border-radius: 12px 12px 0 0;
  padding: 13px 20px 16px 20px;
}

.header-text {
  color: white;
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
}
