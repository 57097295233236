.empty-carousel {
  background-color: rgba(244, 244, 244, 0.4);
  border-radius: 12px 0 0 12px;
  width: 100%;
  display: flex;
  padding: 15px 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.empty-carousel.square {
  height: 182px;
  max-height: 182px;
}

.empty-carousel.rectangle {
  height: 236px;
}

.empty-carousel p {
  color: var(--color-primary);
  font-size: 17px;
  font-weight: 400;
  padding: 32px 20px;
  margin: 0;
}

.empty-carousel-img {
  width: 254px;
  height: 236px;
}

.carousel-with-data {
  margin: 0;
  padding: 15px;
  display: flex;
  max-width: 100%;
  white-space: nowrap;
  flex-direction: row;
  flex-wrap: nowrap;
}

.carousel-with-data.square {
  height: 220px;
}

.carousel-with-data.rectangle {
  height: 260px;
}

.carousel-with-data.add-card-height {
  height: 160px;
}
