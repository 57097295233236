#splash-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#target-img {
  width: 66px;
  height: 66px;
}

#splash-screen .loading-title {
  font-size: 17px;
  font-weight: 600;
  color: #370f08;
  margin-bottom: 10px;
}
#splash-screen .loading-sub-title {
  max-width: 520px;
  font-weight: 600;
  color: #666666;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
