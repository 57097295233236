#doodle-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
}

#doodle-symbol img {
  width: 147px;
  height: 44px;
}

#help-area {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
  font-family: Lato;
  text-align: center;
  margin-bottom: 10px;
}
